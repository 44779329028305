exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-schedule-js": () => import("./../../../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-templates-about-template-js": () => import("./../../../src/templates/AboutTemplate.js" /* webpackChunkName: "component---src-templates-about-template-js" */),
  "component---src-templates-blog-category-template-js": () => import("./../../../src/templates/BlogCategoryTemplate.js" /* webpackChunkName: "component---src-templates-blog-category-template-js" */),
  "component---src-templates-blog-list-template-js": () => import("./../../../src/templates/BlogListTemplate.js" /* webpackChunkName: "component---src-templates-blog-list-template-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/BlogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-event-template-js": () => import("./../../../src/templates/EventTemplate.js" /* webpackChunkName: "component---src-templates-event-template-js" */),
  "component---src-templates-instructor-template-js": () => import("./../../../src/templates/InstructorTemplate.js" /* webpackChunkName: "component---src-templates-instructor-template-js" */),
  "component---src-templates-lesson-group-template-js": () => import("./../../../src/templates/LessonGroupTemplate.js" /* webpackChunkName: "component---src-templates-lesson-group-template-js" */),
  "component---src-templates-lesson-online-template-js": () => import("./../../../src/templates/LessonOnlineTemplate.js" /* webpackChunkName: "component---src-templates-lesson-online-template-js" */),
  "component---src-templates-lesson-private-template-js": () => import("./../../../src/templates/LessonPrivateTemplate.js" /* webpackChunkName: "component---src-templates-lesson-private-template-js" */),
  "component---src-templates-lesson-template-js": () => import("./../../../src/templates/LessonTemplate.js" /* webpackChunkName: "component---src-templates-lesson-template-js" */),
  "component---src-templates-user-template-js": () => import("./../../../src/templates/UserTemplate.js" /* webpackChunkName: "component---src-templates-user-template-js" */),
  "component---src-templates-yoga-type-template-js": () => import("./../../../src/templates/YogaTypeTemplate.js" /* webpackChunkName: "component---src-templates-yoga-type-template-js" */)
}

