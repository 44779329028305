// // custom typefaces
// import "typeface-montserrat"
// import "typeface-merriweather"

// // normalize CSS across browsers
// import "./src/css/normalize.css"

// // custom CSS styles
// import "./src/css/style.css"

exports.onInitialClientRender = () => {
  return window.scrollTo(0, 0)
}

exports.onServiceWorkerUpdateReady = () => {
  return window.location.reload(true)
}

// ページ遷移時のスクロール動作を制御
exports.shouldUpdateScroll = ({ routerProps: { location } }) => {
  // 全てのページ遷移時に画面上部に自動スクロール
  window.scrollTo(0, 0)
  return false
}
